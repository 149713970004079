<script lang="ts">
  import { fly } from "svelte/transition";
  import { hideToast, toastStore as toast } from "./toast";
</script>

<div class="toast-container">
  {#if $toast}
    <button onclick={hideToast} transition:fly={{ y: 200 }} class="toast">
      <div class="toast-icon">ℹ</div>
      <div class="toast-content">{$toast}</div>
    </button>
  {/if}
</div>

<style>
  .toast-container {
    position: fixed;
    bottom: 2rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 1000;
    pointer-events: none;
    padding: 0 var(--main-margin);
  }

  .toast {
    pointer-events: auto;
    font-size: var(--font-size);
    border: var(--line-width) solid black;
    cursor: default;
    background-color: var(--greyLight);
    padding: var(--main-margin);
    border-radius: 10px;
    max-width: 100vw;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: var(--main-margin);
    align-items: center;
  }

  .toast-content{
    white-space: pre-wrap;
    text-align: left;
  }
</style>
